import React, { Component } from "react";
import Axios from "axios";
import moment from "moment";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";

import { Button, Form, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencilAlt } from "@fortawesome/free-solid-svg-icons";

import { Editor } from "@tinymce/tinymce-react";

export class Custom extends Component {
  handleEditorChange = (e) => {
    this.setState({ description: e.target.getContent() });
  };

  //DELETE FUNCTION
  delete = (id) => {
    Axios({
      method: "delete",
      url: process.env.REACT_APP_API_URL + "/custom/" + id,
    }).then((res) => {
      if (res.data.error === false) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      this.getCustomData();
    });
  };

  //Edit FUNCTION
  edit = (id) => {
    Axios({
      method: "get",
      url: process.env.REACT_APP_API_URL + "/custom/" + id,
    }).then((res) => {
      this.setState({
        id: id,
        link: res.data[0].link,
        title: res.data[0].title,
        description: res.data[0].description,
      });
    });
    this.handleShow();
  };

  eSubmit = (event, id) => {
    event.preventDefault();
    Axios({
      method: "put",
      url: process.env.REACT_APP_API_URL + "/custom/" + this.state.id,
      data: {
        link: this.state.link,
        title: this.state.title,
        description: this.state.description,
      },
    }).then((res) => {
      if (res.data.error === false) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      this.getCustomData();
    });
    this.handleClose();
  };
  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  // Submit Trigger
  submit = (event) => {
    event.preventDefault();
    Axios({
      method: "post",
      url: process.env.REACT_APP_API_URL + "/custom",
      data: {
        link: this.linkRef.current.value,
        title: this.titleRef.current.value,
        description: this.state.description,
      },
    }).then((res) => {
      if (res.data.error === false) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      this.getCustomData();
    });
  };

  // Display Trigger
  async getCustomData(page = 0, pageSize = 10, searchTerm = "") {
    const result = await Axios.get(
      `${process.env.REACT_APP_API_URL}/custom?page=${page}&pageSize=${pageSize}&search=${searchTerm}`
    );
    this.setState({
      data: result.data.items,
      totalPages: result.data.totalPages,
      totalCount: result.data.totalCount,
      loading: false,
    });
  }

  componentDidMount() {
    this.getCustomData();
  }

  // Filter Table
  handleChange = (event) => {
    const searchTerm = event.target.value;
    this.setState({ searchInput: searchTerm, loading: true });
    this.getCustomData(0, 10, searchTerm);
  };

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filteredData: [],
      searchInput: "",
      loading: true,
      totalPages: 0,
      totalCount: 0,

      // Edit
      show: false,
      id: "",
      link: "",
      title: "",
      description: "",
    };
    this.linkRef = React.createRef(null);
    this.titleRef = React.createRef(null);
  }

  render() {
    const columns = [
      { Header: "Id", accessor: "id" },
      { Header: "Title", accessor: "title" },
      {
        Header: "Link",
        accessor: "link",
        Cell: ({ row }) => (
          <a
            href={`https://autoones.com/auction-sheet/${row.link}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Link
          </a>
        ),
      },
      {
        Header: "Created At",
        accessor: "created_at",
        Cell: ({ row }) =>
          moment(row._original.created_at).format("MMMM Do, YYYY / h:m a"),
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ row }) => (
          <div>
            <FontAwesomeIcon
              className="text-danger mr-3"
              icon={faTrash}
              onClick={() => this.delete(row.id)}
            />
            <FontAwesomeIcon
              className="text-info ml-3"
              onClick={() => this.edit(row.id)}
              icon={faPencilAlt}
            />
          </div>
        ),
      },
    ];

    return (
      <>
        <div className="card col-sm-12">
          <div className="card-body">
            <Form onSubmit={this.submit}>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Group className="mb-3" controlId="link">
                    <Form.Label>Link</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Link"
                      ref={this.linkRef}
                    />
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="title" className="mb-3">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Link"
                      ref={this.titleRef}
                    />
                  </Form.Group>
                </div>

                <div className="col-sm-12 mt-5">
                  <Form.Label htmlFor="basic-url">Description</Form.Label>
                  <Editor
                    tinymceScriptSrc={
                      process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"
                    }
                    apiKey="zpa6jhahb7wr51wcc4yrbt91xeuizav1kudmrtpziohibpz4"
                    init={{
                      height: 500,
                      menubar: true,
                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "preview",
                        "help",
                        "wordcount",
                      ],
                      toolbar:
                        "undo redo | blocks | " +
                        "bold italic forecolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                    onChange={this.handleEditorChange}
                  />
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="submit">
                    <Form.Label>Submit</Form.Label>
                    <Button variant="primary" type="submit" size="lg" block>
                      Submit
                    </Button>
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>
        </div>

        <div className="card mt-2">
          <div className="card-body">
            <div className="my-3 ">
              <label>Search: </label>
              <input
                name="searchInput"
                onChange={this.handleChange}
                label="Search"
                className="ml-3"
              />
            </div>
            <ReactTable
              data={this.state.data}
              columns={columns}
              defaultPageSize={10}
              loading={this.state.loading}
              pages={this.state.totalPages}
              manual
              onFetchData={(state) => {
                this.setState({ loading: true });
                this.getCustomData(
                  state.page,
                  state.pageSize,
                  this.state.searchInput
                );
              }}
            />
          </div>
        </div>

        {/*here its edited modal*/}
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Body>
            <Form onSubmit={this.eSubmit}>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Group controlId="Link">
                    <Form.Label>Link</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => this.setState({ link: e.target.value })}
                      value={this.state.link}
                      placeholder="Link"
                    />
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="title">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => this.setState({ title: e.target.value })}
                      value={this.state.title}
                      placeholder="Title"
                    />
                  </Form.Group>
                </div>

                <div className="col-sm-12">
                  <Editor
                    initialValue={this.state.description}
                    apiKey="zpa6jhahb7wr51wcc4yrbt91xeuizav1kudmrtpziohibpz4"
                    init={{
                      height: 500,
                      menubar: true,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                    }}
                    onChange={(e) =>
                      this.setState({
                        description: e.target.getContent(),
                      })
                    }
                  />
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="submit">
                    <Form.Label>Submit</Form.Label>
                    <Button variant="primary" type="submit" size="lg" block>
                      Submit
                    </Button>
                  </Form.Group>
                </div>

                <div className="col-sm-6">
                  <Form.Group controlId="close">
                    <Form.Label>Close</Form.Label>
                    <Button
                      variant="danger"
                      type="button"
                      size="lg"
                      block
                      onClick={this.handleClose}
                    >
                      Close
                    </Button>
                  </Form.Group>
                </div>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        <ToastContainer autoClose={3000} />
      </>
    );
  }
}
