import React, { Component } from "react";
import Axios from "axios";

import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import moment from "moment";

export class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      totalPages: 0,
      totalCount: 0,
      searchInput: "",
    };
  }

  componentDidMount() {
    this.getPaymentData();
  }

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  // Display Trigger
  async getPaymentData(page = 0, pageSize = 10, searchTerm = "") {
    try {
      const result = await Axios.get(
        `${process.env.REACT_APP_API_URL}/as/sheets?page=${page}&pageSize=${pageSize}&search=${searchTerm}`
      );
      this.setState({
        data: result.data.items,
        totalPages: result.data.totalPages,
        totalCount: result.data.totalCount,
        loading: false,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      this.setState({ loading: false });
    }
  }

  // Handle Search
  handleChange = (event) => {
    const searchTerm = event.target.value;
    this.setState({ searchInput: searchTerm, loading: true });
    this.getPaymentData(0, 10, searchTerm);
  };

  render() {
    const columns = [
      { Header: "Id", accessor: "id" },
      {
        Header: "Link",
        accessor: "link",
        Cell: ({ row }) => (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={
              "https://autoones.com/auction-sheet/verified-auction-sheet/" +
              row._original.link
            }
          >
            Link
          </a>
        ),
      },
      { Header: "Chassis", accessor: "chassis" },
      { Header: "Sold", accessor: "total" },
      {
        Header: "Date",
        accessor: "created_at",
        Cell: ({ row }) =>
          moment(row._original.created_at).format("MMMM Do, YYYY / h:m a"),
      },
    ];

    return (
      <>
        <div className="card mt-2">
          <div className="card-body">
            <div className="row">
              <div className="col-sm-3 form-group my-3 ml-3">
                <label>Search: </label>
                <input
                  name="searchInput"
                  className="form-control"
                  onChange={this.handleChange}
                  value={this.state.searchInput}
                  label="Search"
                  placeholder="Search by ID, Link or Chassis"
                />
              </div>
            </div>
            <ReactTable
              data={this.state.data}
              columns={columns}
              defaultPageSize={10}
              loading={this.state.loading}
              pages={this.state.totalPages}
              manual
              onFetchData={(state) => {
                this.setState({ loading: true });
                this.getPaymentData(
                  state.page,
                  state.pageSize,
                  this.state.searchInput
                );
              }}
            />
          </div>
        </div>

        <ToastContainer autoClose={3000} />
      </>
    );
  }
}
