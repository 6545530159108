import React, { Component } from "react";
import Axios from "axios";
import moment from "moment";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      totalPages: 0,
      totalCount: 0,
      searchInput: "",
      statusFilter: "",
    };
  }

  componentDidMount() {
    this.getPaymentData();
  }

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  // Display Trigger
  async getPaymentData(page = 0, pageSize = 10, searchTerm = "", status = "") {
    try {
      const result = await Axios.get(
        `${process.env.REACT_APP_API_URL}/as?page=${page}&pageSize=${pageSize}&search=${searchTerm}&status=${status}`
      );
      this.setState({
        data: result.data.items,
        totalPages: result.data.totalPages,
        totalCount: result.data.totalCount,
        loading: false,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      this.setState({ loading: false });
    }
  }

  // Handle Search
  handleChange = (event, isStatus = false) => {
    if (isStatus) {
      const status = event.target.value;
      this.setState({ statusFilter: status, loading: true });
      this.getPaymentData(0, 10, this.state.searchInput, status);
    } else {
      const searchTerm = event.target.value;
      this.setState({ searchInput: searchTerm, loading: true });
      this.getPaymentData(0, 10, searchTerm, this.state.statusFilter);
    }
  };

  openLink = (row) => {
    if (window.confirm("Are you sure?")) {
      window.location.href = `https://autoones.com/auction-sheet/success/${(
        (row._original.id * 394672 + 7638) *
        2
      ).toString(16)}`;
    }
  };

  render() {
    const columns = [
      {
        Header: "Id",
        accessor: "id",
        Cell: ({ row }) => (
          <a
            onClick={() => this.openLink(row)}
            target="_blank"
            rel="noopener noreferrer"
            className={`text-center font-weight-bold ${
              row._original.status === "Successful"
                ? "bg-success text-white"
                : "bg-danger text-white"
            }`}
          >
            {row._original.id}
          </a>
        ),
      },
      { Header: "Name", accessor: "name" },
      { Header: "Phone", accessor: "contact" },
      { Header: "Email", accessor: "email" },
      { Header: "CC", accessor: "country" },
      { Header: "Chassis", accessor: "chassis" },
      {
        Header: "Package",
        accessor: "packages",
        Cell: ({ row }) =>
          row._original.packages == 1
            ? "Auction Sheet"
            : row._original.packages == 2
            ? "AS + Translate"
            : row._original.packages == 3
            ? "Sheet Plus"
            : "Translation",
      },
      {
        Header: "Created",
        accessor: "created_at",
        Cell: ({ row }) =>
          moment(row._original.createdAt).format("MMMM Do, YYYY / h:m a"),
      },
      { Header: "Price", accessor: "price" },
      {
        Header: "Method",
        accessor: "paymentMethod",
        Cell: ({ row }) =>
          row._original.paymentMethod == 1 ? "Stripe" : "Paypal",
      },
    ];

    return (
      <>
        <div className="card mt-2">
          <div className="card-body">
            <div className="row">
              <div className="col-sm-3 form-group my-3 ml-3">
                <label>Search: </label>
                <input
                  name="searchInput"
                  className="form-control"
                  onChange={(e) => this.handleChange(e, false)}
                  value={this.state.searchInput}
                  placeholder="Search by Name, Chassis, Contact, etc."
                />
              </div>
              <div className="col-sm-3 form-group my-3 ml-3">
                <label>Status Filter: </label>
                <select
                  name="statusFilter"
                  className="form-control"
                  onChange={(e) => this.handleChange(e, true)}
                  value={this.state.statusFilter}
                >
                  <option value="">All</option>
                  <option value="Initiated">Initiated</option>
                  <option value="Successful">Successful</option>
                  <option value="Failed">Failed</option>
                </select>
              </div>
            </div>
            <ReactTable
              data={this.state.data}
              columns={columns}
              defaultPageSize={10}
              loading={this.state.loading}
              pages={this.state.totalPages}
              manual
              onFetchData={(state) => {
                this.setState({ loading: true });
                this.getPaymentData(
                  state.page,
                  state.pageSize,
                  this.state.searchInput,
                  this.state.statusFilter
                );
              }}
            />
          </div>
        </div>

        <ToastContainer autoClose={3000} />
      </>
    );
  }
}
